var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permission.read_perm === 1)?_c('div',{staticClass:"fluid",attrs:{"id":"requestList"}},[(_vm.isAdmin)?_c('div',[_c('div',{staticClass:"request-list-div"},[_c('v-card',{staticClass:"px-5 py-1"},[_c('v-row',[_c('v-col',{staticClass:"d-flex",staticStyle:{"position":"relative","padding":"0 20px","text-align":"center","margin-top":"20px"},attrs:{"cols":"12"}},[_c('h4',{staticClass:"dmsCategoryTitle1"},[_vm._v(" ARCHIVE MANAGEMENT SYSTEM "),_c('v-tooltip',{attrs:{"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","icon":"","color":"grey darken-2"},on:{"click":_vm.refresh}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]}}],null,false,2775313230)},[_c('span',[_vm._v("refresh page")])])],1),_c('h4',{staticClass:"dmsCategoryTitle2"},[_vm._v(" Peminjaman Dokumen "),_c('v-tooltip',{attrs:{"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","icon":"","color":"grey darken-2"},on:{"click":_vm.refresh}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]}}],null,false,2775313230)},[_c('span',[_vm._v("refresh page")])])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"request-list-toolbar-1"},[_c('v-toolbar',{staticClass:"request-list-toolbar-div",staticStyle:{"height":"60px","border-radius":"10px 10px 0 0"},attrs:{"flat":""}},[_c('v-toolbar-title',{staticStyle:{"color":"indigo"}},[_vm._v(" Peminjaman Dokumen")]),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),(_vm.permission.create_perm == 1)?_c('div',{staticStyle:{"margin-left":"10px"}},[_c('v-btn',{staticClass:"indigo--text",staticStyle:{"font-size":"12px"},attrs:{"rounded":"","outlined":"","small":"","elevation":"1","color":"indigo"},on:{"click":_vm.borrow}},[_vm._v(" Pinjam ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"request-list-toolbar-1"},[_c('v-toolbar',{staticClass:"request-list-toolbar-div",staticStyle:{"height":"40px"},attrs:{"flat":""}},[(_vm.permission.update_perm == 1)?_c('div',{staticStyle:{"width":"120px","margin-right":"20px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","font-size":"12px"},attrs:{"disabled":_vm.selectedData.length === 0,"label":"Action","items":[
                      { id: 0, name: '' },
                      { id: 2, name: 'Return' }
                    ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},on:{"change":_vm.action},model:{value:(_vm.actionValue),callback:function ($$v) {_vm.actionValue=$$v},expression:"actionValue"}})],1):_vm._e(),_c('div',{staticStyle:{"width":"120px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","margin-right":"20px","font-size":"12px"},attrs:{"label":"Status","items":[
                      { id: '', name: 'All' },
                      { id: 0, name: 'Need Approval' },
                      { id: 8, name: 'Returned' },
                      { id: 9, name: 'Granted Download' },
                      { id: 10, name: 'Approved' },
                      { id: -1, name: 'Reject' }
                    ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},model:{value:(_vm.paramAPI.status),callback:function ($$v) {_vm.$set(_vm.paramAPI, "status", $$v)},expression:"paramAPI.status"}})],1),_c('v-text-field',{staticStyle:{"position":"relative","top":"15px"},attrs:{"label":"Cari request","type":"search","append-icon":"mdi-magnify","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)],1),_c('div',{staticClass:"request-list-toolbar-2"},[_c('v-expansion-panels',{attrs:{"flat":"","accordion":""}},_vm._l((1),function(item,i){return _c('v-expansion-panel',{key:i,attrs:{"flat":""}},[_c('v-expansion-panel-header',{staticClass:"d-flex flex-column align-items-start",staticStyle:{"padding":"0","margin":"0"}},[_c('v-toolbar',{staticClass:"request-list-toolbar-2",staticStyle:{"height":"60px","width":"100%"},attrs:{"flat":""}},[(_vm.permission.update_perm == 1)?_c('div',{staticStyle:{"width":"120px","margin-right":"20px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","font-size":"12px"},attrs:{"disabled":_vm.selectedData.length === 0,"label":"Action","items":[
                            { id: 0, name: '' },
                            { id: 2, name: 'Return' }
                          ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},on:{"click":function($event){$event.stopPropagation();},"change":_vm.action},model:{value:(_vm.actionValue),callback:function ($$v) {_vm.actionValue=$$v},expression:"actionValue"}})],1):_vm._e(),(_vm.permission.create_perm == 1)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('v-btn',{staticClass:"indigo--text",staticStyle:{"font-size":"12px"},attrs:{"rounded":"","outlined":"","small":"","elevation":"1","color":"indigo"},on:{"click":_vm.borrow}},[_vm._v(" Pinjam ")])],1):_vm._e()]),_c('v-toolbar',{staticStyle:{"height":"60px","border-bottom":"1px solid #e0e0e0","width":"100%"},attrs:{"flat":""}},[_c('v-text-field',{staticStyle:{"position":"relative","top":"15px"},attrs:{"label":"Cari request","type":"search","append-icon":"mdi-magnify","outlined":"","dense":""},on:{"click":function($event){$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)],1),_c('v-expansion-panel-content',{attrs:{"id":"expansionPanelContent"}},[_c('v-toolbar',{staticStyle:{"height":"60px"},attrs:{"flat":""}},[_c('div',{staticStyle:{"width":"120px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","margin-right":"20px","font-size":"12px"},attrs:{"label":"Status","items":[
                            { id: '', name: 'All' },
                            { id: 0, name: 'Need Approval' },
                            { id: 2, name: 'Loaned' },
                            { id: 8, name: 'Returned' },
                            { id: 9, name: 'Granted Download' },
                            { id: 10, name: 'Approved' },
                            { id: -1, name: 'Reject' }
                          ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},model:{value:(_vm.paramAPI.status),callback:function ($$v) {_vm.$set(_vm.paramAPI, "status", $$v)},expression:"paramAPI.status"}})],1)])],1)],1)}),1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticStyle:{"cursor":"pointer","min-width":"1200px"},attrs:{"mobile-breakpoint":"0","fixed-header":"","height":"55vh","headers":_vm.headers,"items":_vm.result,"loading":_vm.loading,"show-select":_vm.permission.update_perm === 1,"item-key":"id","single-select":true,"options":_vm.options,"footer-props":{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                },"server-items-length":_vm.totalData},on:{"click:row":_vm.rowClick,"update:options":function($event){_vm.options=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemPerPage},scopedSlots:_vm._u([{key:"item.loan_number",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(item.loan_number)+" ")])]}},{key:"item.doc.doc_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(item.doc.doc_name)+" ")])]}},{key:"item.doc.doc_number",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(item.doc.doc_number)+" ")])]}},{key:"item.doc.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(item.doc.name)+" ")])]}},{key:"item.loan_start_date",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.loan_start_date))+" ")])]}},{key:"item.loan_end_date",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"arch-expDate"},[_c('div',{staticClass:"arch-exp-date"},[_c('div',{staticStyle:{"width":"120px","padding":"0 5px"}},[_c('p',{staticStyle:{"margin":"0","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.loan_end_date))+" ")])]),(item.status !== null)?_c('div',{staticClass:"arch-exp-date-div1"},[(_vm.countdown(item.loan_end_date) <= '7')?_c('div',{staticClass:"arch-exp-date-notif"},[(item.status.id === 2)?_c('div',{staticClass:"exp-date-notif-div"},[(_vm.countdown(item.loan_end_date) > '0')?_c('v-chip',{staticClass:"exp-date-notif-chip1",attrs:{"small":"","color":"orange","text-color":"white"}},[_vm._v(" Done in "+_vm._s(_vm.countdown(item.loan_end_date))+" days ")]):(_vm.countdown(item.loan_end_date) < '0')?_c('v-chip',{staticClass:"exp-date-notif-chip2",attrs:{"small":"","color":"red","text-color":"white"}},[_c('p',{staticClass:"date-notif-chip2-p"},[_vm._v(" exceeded ")])]):_c('v-chip',{staticClass:"exp-date-notif-chip2",attrs:{"small":"","color":"pink","text-color":"white"}},[_c('p',{staticClass:"date-notif-chip2-p"},[_vm._v("Done")])])],1):_vm._e()]):_vm._e()]):_vm._e()])])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.convertDate2(item.created_at))+" ")])]}},{key:"item.pic_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" "+_vm._s(item.doc.document_type_id === 2 ? item.employee.name : item.pic_name)+" ")])]}},{key:"item.doc",fn:function(ref){
                var item = ref.item;
return [(item.doc.document_type_id === 2)?_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" SCP ")]):_vm._e(),(item.doc.document_type_id === 1)?_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row","font-size":"12px"}},[_vm._v(" HCP ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [(item.status !== null)?_c('div',[(item.status.id === 9)?_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[(item.email === _vm.getUserProfile.employee.email)?_c('div',[_c('p',{staticStyle:{"margin":"0","width":"90px","position":"relative","font-size":"12px"}},[_c('span',{staticClass:"docSpanDownload green--text",on:{"click":function($event){$event.stopPropagation();return _vm.downloadSoftCopy(item)}}},[_vm._v("Download "),_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-download")])],1)])]):_c('div',{style:(("padding: 0; display: flex; flex-direction: row;color:" + (_vm.status(
                            item.status
                          ))))},[_vm._v(" "+_vm._s(item.status.name)+" ")])]):_c('div',{style:(("padding: 0; display: flex; flex-direction: row;color:" + (_vm.status(
                          item.status
                        ))))},[_vm._v(" "+_vm._s(item.status.name)+" ")])]):_vm._e()]}}],null,true),model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}})],1)]),_c('div',{staticClass:"pr-items-add-new-line"},[_c('ReturnDocument',{attrs:{"param":{
                items: _vm.itemEdit
              },"permission":_vm.permission,"isAdmin":_vm.isAdmin},on:{"loadPage":_vm.getDataFromApi}})],1)],1)],1)],1)]):_c('div',[_c('UserView',{attrs:{"isUser":_vm.isAdmin,"permission":_vm.permission}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }